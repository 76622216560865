import React, { createRef, useEffect, useRef, useState } from 'react'
import Head from 'next/head'
import styles from 'styles/Home.module.scss'
import Header from 'component/Header/Header'
import ContainerFluid from 'component/ContainerFluid'
import ServiceCard from 'component/ServiceCard'
import classNames from 'classnames'
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import ScrollToTop from 'component/ScrollToTop'
import Footer from 'component/Footer'
import Image from '../component/ImgComponent';
import { motion } from 'framer-motion'
import TopBanner from 'component/Home/TopBanner'
import StickyContact from 'component/StickyContact'
import Link from 'next/link'
import TopBannerButton from 'component/Home/TopBannerButton'
import { InView } from 'react-intersection-observer';
import Marker from 'component/Marker'
import Particles from 'react-particles-js'
import NewsList from '../component/News/NewsList'
import { getNewsList, getSurveyQuestion } from '../actions/requests'
import langContent from '../i18n/home'
import HtmlRender from '../component/HtmlRender'
import Loading from '../component/Loading'
import { useRouter } from 'next/router'
import { generateRss } from "lib/rss";
import fs from "fs";

export default function Home({ newsList, questions, locale }) {
  const [inViewList, setInView] = useState()
  const router = useRouter()

  const variants = {
    visible: {
      opacity: 1,
      scale: 1
    },
    hidden: {
      opacity: 0,
      scale: 0,
    }
  }
  const parentVariants = {
    visible: {
      transition: {
        staggerChildren: 0.05,
        delayChildren: 0.3,
      }
    },
    hidden: {
      transition: { staggerDirection: -1 }
    }
  }

  const onInViewChange = (inView, entry, threshole) => {
    let _classname = entry.target.classList[0]
    if (entry.isIntersecting && entry.intersectionRatio >= threshole) {
      setInView(_classname)
    }
  }

  const data = {
    data: locale === "vi" ? [
      {
        id: 1,
        created_at: "2021-06-20",
        title: "Xu thế tất yếu của thị trường phần mềm cho doanh nghiệp Việt Nam",
        short_content: "SaaS - phần mềm dành cho doanh nghiệp chắc chắn sẽ trở thành xu thế và cũng là lựa chọn của các doanh nghiệp Việt Nam.",
        images: {
          image: "h1.png"
        },
      },
      {
        id: 2,
        created_at: "2021-06-19",
        title: "5 phần mềm hỗ trợ nổi bật dành cho nông nghiệp",
        short_content: "Ứng dụng công nghệ trong nông nghiệp đang ngày càng thể hiện ưu thế vượt trội so với nông nghiệp truyền thống.",
        images: {
          image: "h2.png"
        },
      },
      {
        id: 4,
        created_at: "2021-06-01",
        title: "Những bất cập trong các ứng dụng chống dịch",
        short_content: "Việt Nam có ít nhất bốn nền tảng khai báo y tế, truy vết khiến người dân bối rối mỗi khi cung cấp thông tin cho chính quyền.",
        images: {
          image: "h3.png"
        },
      },
    ] : [
      {
        id: 1,
        created_at: "2021-06-20",
        title: "The inevitable trend of the software market in Vietnam",
        short_content: "According to experts' predictions, along with a strong wave of technology, SaaS - software for businesses will definitely become the trend and also the choice of Vietnamese businesses.",
        images: {
          image: "h1.png"
        },
      },
      {
        id: 2,
        created_at: "2021-06-19",
        title: "5 outstanding software programs for agriculture",
        short_content: "The application of technology in agriculture is increasingly showing its superiority over traditional agriculture.",
        images: {
          image: "h2.png"
        },
      },
      {
        id: 4,
        created_at: "2021-06-01",
        title: "Inadequacies in anti - epidemic applications",
        short_content: "Vietnam has at least four medical declaration and tracing platforms that confuse people every time they provide information to the authorities.",
        images: {
          image: "h3.png"
        },
      },
    ],
    total: 3,
  }

  return (
    <>
      <Head>
        <title>Estuary Solutions</title>
        <meta prefix="og: http://ogp.me/ns#" name="title" property="og:title" content="Estuary Solutions"></meta>
        <meta property="description" content={
          locale === "vi"
          ? `Chúng tôi là Estuary - Công ty chuyển đổi số chuyên cung cấp các giải pháp kinh doanh đột phá nhằm thúc đẩy thị trường, tiếp thị sản phẩm và quản lý vận hành thông qua SalesTech và ERP.`
          : `We are Estuary. A digital transformation company dedicated to provide innovative business solutions focusing on growth hacking, SalesTech & Operation management solutions.`
        }
        ></meta>
        <meta prefix="og: http://ogp.me/ns#" name="description" property="og:description" content={
          locale === "vi"
          ? `Chúng tôi là Estuary - Công ty chuyển đổi số chuyên cung cấp các giải pháp kinh doanh đột phá nhằm thúc đẩy thị trường, tiếp thị sản phẩm và quản lý vận hành thông qua SalesTech và ERP.`
          : `We are Estuary. A digital transformation company dedicated to provide innovative business solutions focusing on growth hacking, SalesTech & Operation management solutions.`
        }
        ></meta>
        <link rel="prefetch" href="/static/vn-flag.png" />
        <link rel="prefetch" href="/static/uk-flag.png" />
        <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"/>
        <meta prefix="og: http://ogp.me/ns#" name="image" property="og:image" content={`${process.env.NEXT_PUBLIC_MEDIA_FILE}f2b2acb7-e4a2-47cc-8f31-166caaaedf50`}></meta>
        <meta prefix="og: http://ogp.me/ns#" property="og:type" content="website"></meta>
        <meta prefix="og: http://ogp.me/ns#" property="og:url" content={`https://estuary.solutions/${locale}`}></meta>
        <link rel="canonical" href="https://estuary.solutions" />
        {/* <link rel="shortcut icon" href={`https://iwsapi.vemedim.vn/vmd-iws-mediafile/file/a5ab3ee7-77a8-481d-8e03-65e7c95f9606`} type="image/x-icon" /> */}
        <link rel="shortcut icon" href={`${process.env.NEXT_PUBLIC_MEDIA_FILE}299c980f-d8d5-406e-8fe1-432221fa6896`} type="image/x-icon" />
        <meta name="keywords" content="Estuary Solutions" />
        <meta name="google-site-verification" content="6n3Wp03vZ36Noklv85xu6JvwOn7gCydfNscFhpoBDH0" />
      </Head>
      <Header inViewList={inViewList} />
      <ContainerFluid className="main-layout">
        <ScrollToTop />
        <InView
          threshold={0.6}
          trackVisibility={true}
          delay={100}
          as="div"
          className="home-banner w-100"
          onChange={(inView, entry) => onInViewChange(inView, entry, 0.6)}
        >
          <ContainerFluid
            className="content-wrapper home-banner-fluid"
          >
            <TopBanner
              title={langContent.banner.title[locale]}
              description={
                <>
                  <HtmlRender className="mb-3 mb-md-4">
                    {langContent.banner.description[0][locale]}
                  </HtmlRender>
                  <HtmlRender className="d-none d-md-block mb-3 mb-md-4">
                    {langContent.banner.description[1][locale]}
                  </HtmlRender>
                  <HtmlRender>
                    {langContent.banner.description[2][locale]}
                  </HtmlRender>
                </>
              }
              // actions="none"
              actions={
                () => (
                  questions?.data?.length && questions.data.length > 0 ?
                  <Link href="/survey">
                    <TopBannerButton
                      title={
                        locale === "vi"
                          ? "Khảo sát doanh nghiệp của bạn chỉ trong 5 phút"
                          : "Get your report in 5 minutes"
                      }
                    />
                  </Link>
                  : undefined
                )
              }
              particle={
                <Particles
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0
                  }}
                  params={{
                    // interactivity: {
                    //   events: {
                    //     onHover: {
                    //       enable: true,
                    //       mode: "repulse"
                    //     }
                    //   },
                    // },
                    particles: {
                      number: {
                        value: 6,
                        density: {
                          enable: false,
                          value_area: typeof window !== 'undefined' ? window.innerWidth : 800
                        }
                      },
                      line_linked: {
                        enable: false
                      },
                      move: {
                        speed: 5,
                        //out_mode: "out"
                      },
                      opacity: {
                        value: 0.7,
                        anim: {
                          enable: false
                        },
                        random: {
                          enable: true,
                          minimumValue: 0.4
                        }
                      },
                      rotate: {
                        value: 0,
                        animation: {
                          enable: true,
                          speed: 20
                        }
                      },
                      shape: {
                        type: [
                          "image",
                        ],
                        image: [
                          {
                            src: "/static/particle.png",
                            height: 77,
                            width: 77
                          },
                          {
                            src: "/static/particle2.png",
                            height: 45,
                            width: 45
                          },
                        ]
                      },
                      size: {
                        value: 30,
                        random: {
                          enable: true,
                          minimumValue: 10
                        },
                        anim: {
                          enable: false,
                          speed: 1,
                          size_min: 50,
                          sync: false
                        }
                      }
                    },
                    retina_detect: false
                  }}
                />
              }
            >
              <div className="col-md-6 col-12">
                <div className={classNames(styles.rightPanel, "position-relative")}>
                  <div className={classNames(styles.circleBg, styles.fullFill, "d-block d-lg-none")}></div>
                  <motion.div className={classNames(styles.rightPanelImg, styles.people)}>
                    <Image width={170} height={210} objectFit="cover" src="/static/home_people.png" alt="people" />
                  </motion.div>
                  <motion.div
                    initial={{
                      opacity: 0,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      scale: 1,
                    }}
                    transition={{ duration: 0.5 }}
                    className={classNames(styles.rightPanelImg, styles.phone)}
                  >
                    <Image width={290} height={180} objectFit="cover" src="/static/home_phone.png" alt="phone" />
                  </motion.div>
                  <motion.div
                    className={classNames(styles.rightPanelImg, styles.barchart)}
                    initial={{
                      opacity: 0,
                      y: 150,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: 1,
                    }}
                    transition={{
                      duration: 0.5,
                      delay: 0.7
                    }}
                  >
                    <Image width={200} height={240} objectFit="cover" src="/static/home_bar_chart.png" alt="bar_chart" />
                  </motion.div>
                  <motion.div
                    className={classNames(styles.rightPanelImg, styles.boardChart1)}
                    initial={{
                      opacity: 0,
                      y: 150,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: 1,
                    }}
                    transition={{
                      duration: 0.5,
                      delay: 0.9
                    }}
                  >
                    <Image width={110} height={170} objectFit="cover" src="/static/home_board_chart_1.png" alt="chart_1" />
                  </motion.div>
                  <motion.div
                    className={classNames(styles.rightPanelImg, styles.boardChart2)}
                    initial={{
                      opacity: 0,
                      y: 150,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: 1,
                    }}
                    transition={{
                      duration: 0.5,
                      delay: 1
                    }}
                  >
                    <Image width={110} height={170} objectFit="cover" src="/static/home_board_chart_2.png" alt="chart_2" />
                  </motion.div>
                  <motion.div
                    className={classNames(styles.rightPanelImg, styles.boardChart3)}
                    initial={{
                      opacity: 0,
                      y: 150,
                      scale: 0,
                    }}
                    animate={{
                      opacity: 1,
                      y: 0,
                      scale: 1,
                    }}
                    transition={{
                      duration: 0.5,
                      delay: 1.1
                    }}
                  >
                    <Image width={125} height={175} objectFit="cover" src="/static/home_board_chart_3.png" alt="chart_3" />
                  </motion.div>
                </div>
                <HtmlRender className={classNames(styles.homeCustomContent,"d-block d-md-none mb-3 mb-md-4")}>
                  {langContent.banner.description[1][locale]}
                </HtmlRender>
              </div>
            </TopBanner>
          </ContainerFluid>
        </InView>
        {/* <InView
          threshold={0.6}
          trackVisibility={true}
          delay={100}
          as="div"
          className="home-work w-100"
          onChange={(inView, entry) => onInViewChange(inView, entry, 0.6)}
        >
          <ContainerFluid
            className="home-work-fluid"
          >
            <div className={classNames("container", styles.maps)}>
              <HtmlRender>
                {langContent.our_work.paragraph1[locale]}
              </HtmlRender>
              <div className={styles.mapsWrapper}>
                <motion.div
                  initial="hidden"
                  animate={inViewList === "home-work" && "visible"}
                >
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    className="text-center"
                  >
                    <Image style={{ width: "100%", height: "100%" }} layout="responsive" width={700} height={450} src="/static/vector_maps.svg" alt="maps" />
                  </motion.div>
                  <motion.div
                    variants={parentVariants}
                  >
                    <Marker
                      className="markerUsa"
                      title="USA"
                      width={45}
                      height={53}
                    />
                    <Marker
                      className="markerGermany"
                      title="Germany"
                      width={35}
                      height={41}
                    />
                    <Marker
                      className="markerKorea"
                      title="Korea"
                      width={28}
                      height={33}
                    />
                    <Marker
                      className="markerMyanmar"
                      title="Myanmar"
                      width={14}
                      height={16}
                    />
                    <Marker
                      className="markerCambodia"
                      title="Cambodia"
                      width={14}
                      height={16}
                    />
                    <Marker
                      className="markerThailand"
                      title="Thailand"
                      width={10}
                      height={12}
                    />
                    <Marker
                      className="markerVietnam"
                      title="Vietnam"
                      width={10}
                      height={12}
                    />
                    <Marker
                      className="markerVietnam2"
                      title=""
                      width={10}
                      height={12}
                    />
                  </motion.div>
                </motion.div>
              </div>
              <p className={classNames("text-center mb-1", styles.vision)} dangerouslySetInnerHTML={{ __html: langContent.our_work.paragraph2[locale] }}>
              </p>
              <p className={classNames("text-center mb-3", styles.vision)} dangerouslySetInnerHTML={{ __html: langContent.our_work.paragraph3[locale] }}>
              </p>
              <p className={classNames("text-center mb-1", styles.vision)} dangerouslySetInnerHTML={{ __html: langContent.our_work.paragraph5[locale] }}>
              </p>
            </div>
          </ContainerFluid>
        </InView> */}
        <InView
          threshold={0.4}
          trackVisibility={true}
          delay={100}
          as="div"
          className="home-solution w-100"
          onChange={(inView, entry) => onInViewChange(inView, entry, 0.4)}
        >
          <ContainerFluid
            className={classNames("home-solution-fluid", styles.services)}
          >
            <div className={classNames(styles.bg_gradient, styles.bg_gradient_top, "bg-gradient-top")}></div>
            <div className={classNames(styles.bg_gradient, styles.bg_gradient_bottom, "bg-gradient-bottom")}></div>
            <div
              className="container"
            >
              {/* <div className="row"> */}
                <h2 className={styles.title}>{langContent.service.title[locale]}</h2>
                {/* <HtmlRender>
                  {langContent.service.thinking[locale]}
                </HtmlRender> */}
                <div className="row">
                  {langContent.service.list.map(item => (
                    <ServiceCard 
                      id={item.slug}
                      key={item.id}
                      title={
                        item.title[locale]
                      }
                      description={
                        item.sub_title[locale]
                      }
                      // src="/static/service_manufacturing_system.png"
                      lottieFile={item.lotties}
                    />
                  ))}
                  {/* <ServiceCard 
                    id={langContent.service.list[1].id}
                    key={langContent.service.list[1].id}
                    title={
                      langContent.service.list[1].title[locale]
                    }
                    description={
                      langContent.service.list[1].sub_title[locale]
                    }
                    // src="/static/service_test.png"
                    lottieFile={langContent.service.list[1].lotties}
                    customSize={true}
                    className="custom-margin"
                  />
                  <ServiceCard 
                    id={langContent.service.list[2].id}
                    key={langContent.service.list[2].id}
                    title={
                      langContent.service.list[2].title[locale]
                    }
                    description={
                      langContent.service.list[2].sub_title[locale]
                    }
                    // src="/static/service_internal_work.png"
                    lottieFile={langContent.service.list[2].lotties}
                  />
                  <ServiceCard 
                    id={langContent.service.list[3].id}
                    key={langContent.service.list[3].id}
                    title={
                      langContent.service.list[3].title[locale]
                    }
                    description={
                      langContent.service.list[3].sub_title[locale]
                    }
                    // src="/static/service_loyalty.png"
                    lottieFile={langContent.service.list[3].lotties}
                  />
                  <ServiceCard 
                    id={langContent.service.list[4].id}
                    key={langContent.service.list[4].id}
                    title={
                      langContent.service.list[4].title[locale]
                    }
                    description={
                      langContent.service.list[4].sub_title[locale]
                    }
                    // src="/static/service_elearning.png"
                    lottieFile={langContent.service.list[4].lotties}
                  />
                  <ServiceCard 
                    id={langContent.service.list[5].id}
                    key={langContent.service.list[5].id}
                    title={
                      langContent.service.list[5].title[locale]
                    }
                    description={
                      langContent.service.list[5].sub_title[locale]
                    }
                    // src="/static/service_platform.png"
                    lottieFile={langContent.service.list[5].lotties}
                  /> */}
                </div>
              {/* </div> */}
            </div>
          </ContainerFluid>
        </InView>
        <InView
          threshold={0.6}
          trackVisibility={true}
          delay={100}
          as="div"
          className="home-news w-100"
          onChange={(inView, entry) => onInViewChange(inView, entry, 0.6)}
        >
          <div className="p-4 banner-contact-wrapper">
            <div className="banner-contact p-3 d-flex flex-column flex-md-row justify-content-around align-items-center">
              <div>
                {locale === "vi" 
                  ? <p className="text-center">Nói với chúng tôi về <br/><span>vấn đề doanh nghiệp bạn đang gặp phải</span></p>
                  : <p className="text-center">Tell us about <br/><span>your business problem</span></p>
                }
              </div>
              <div className="mt-3 mt-md-0">
                <StickyContact directClick={() => router.push("/contact")} noSticky/>
              </div>
            </div>
          </div>
          {newsList &&
            <NewsList
              locale={locale}
              newsList={newsList}
              // newsList={data}
            />
          }
        </InView>
        <Footer />
        <StickyContact />
      </ContainerFluid>
    </>
  )
}

export const getStaticProps = async (params) => {
  const res = await getNewsList({
    lang_id: params.locale === "vi" ? 1 : 2,
    status: 1
  })
  const rss = await generateRss(res.data.data, params.locale);
  fs.writeFileSync("./public/rss.xml", rss);
  
  const { data } = res

  let questions = await getSurveyQuestion({
    lang_id: params.locale === "vi" ? 1 : 2,
    status: 1
  })
  // if (!data || !questions.data) {
  //   return {
  //     props: {
  //       newsList: [],
  //       questions: undefined
  //     }
  //   }
  // }

  return {
    props: {
      newsList: data ? data : undefined,
      questions: questions?.data ? questions.data : undefined
    }, // will be passed to the page component as props
    revalidate: 1,
  }
}