import classNames from 'classnames';
import Image from '../ImgComponent';
import Link from 'next/link';
import React from 'react';
import { ChevronRight } from 'react-bootstrap-icons';
import styles from './NewsCard.module.scss'
import moment from 'moment-mini'

const NewsCard = ({ id, slug, title, desc, created_at, src, locale, objectFit, className, classButton }) => {
  let cloneSlug = slug?.split("-") ?? []
  cloneSlug.splice(cloneSlug.length - 1, 1)
  return (
    <div className={classNames("cursor-pointer", styles.newsCardWrapper, className)}>
      <p className={classNames(styles.newsCreatedAt, "text-right mb-2")}>
        <small>{moment(created_at).format(locale === "vi" ? "DD/MM/YYYY" : "DD MMMM YYYY")}</small>
      </p>
      <Link as={`/news/${cloneSlug?.join("-")}-${id}`} href={`/news/${id}`}>
        <div className="w-100">
          <Image objectFit={objectFit || "cover"} width={480} height={270} layout="responsive" className={classNames("img-fluid", styles.newsImg)} src={src} alt={title + " thumb"} />
        </div>
      </Link>
      <Link as={`/news/${slug}-${id}`} href={`/news/${id}`}>
        <p
          className={
            classNames(
              "mb-1 text-darken-blue default-text-size",
              "font-weight-bold",
              "mt-2",
              styles.newsCardTitle
            )
          }
        >
          {title}
        </p>
      </Link>
      <Link as={`/news/${slug}-${id}`} href={`/news/${id}`}>
        <p className={classNames("mb-2", styles.newsCardDesc)}>{desc}</p>
      </Link>
      <Link as={`/news/${slug}-${id}`}
        href={`/news/${id}`}
      >
        <div className={classNames(styles.readMore, "mt-auto d-flex align-items-center cursor-pointer", classButton)}>
          <span className="text-dark-blue">{locale === "vi" ? "Đọc tiếp" : "Read more"}</span>
          <ChevronRight className="text-dark-blue ml-1" />
        </div>
      </Link>
    </div>
  );
};

export default NewsCard;